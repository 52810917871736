export default {
    data(){
        return {
            dd_abtests_statuses: [
                { label: 'Draft', value: 1 },
                { label: 'Active', value: 2 },
                { label: 'Complete', value: 3 },
            ],
        };
    },
    methods: {
        AbtestStatusLabel(k){
            let name = '-';
            this.dd_abtests_statuses.forEach(s => {
                if (s.value === k) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}
