<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Lista Abtests</h4>
                </div>
                <DataTable :value='rows'
                           :paginator='true'
                           class='p-datatable-gridlines'
                           :rows='20'
                           dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                           :globalFilterFields="['slug']"
                >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='id' header='ID' :sortable='true' style='width:4rem'>
                    </Column>

                    <Column field='slug' header='Slug' :sortable='true' style='min-width:4rem'>
                    </Column>

                    <Column field='status' header='Status' :sortable='true' style='min-width:2rem'>
                        <template #body='slotProps'>
                            {{AbtestStatusLabel(slotProps.data['status'])}}
                        </template>
                    </Column>


                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='pointer ml-2'
                            title='Apri in nuovo Tab'
                            :style='{color: colors.bg.HELP}'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='pointer ml-2'
                            title='Apri nello stesso Tab'
                            :style='{color: colors.bg.INFO}'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 p-mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                            <router-link :to='gotoId(slotProps.data.id)+"/a/"+slotProps.data.id+"/b"' :target='linkInNewPage ? "_blank" : "_self"'>
                                <button class="p-button p-component p-button-icon-only mr-2 p-mb-2 p-button-secondary" type="button">
                                    <font-awesome-icon
                                                       title='Confronta varianti'
                                                       :icon="['fas', 'chart-line']"
                                                       />
                                    <span class="p-button-label">&nbsp;</span>
                                    <span class="p-ink" style="height: 35px; width: 35px; top: 5.5px; left: -2.84375px;"></span>
                                </button>
                            </router-link>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Analytics/Abtests');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import DdAbtestsStatuses from '../../../mixins/dd_abtests_statuses';
export default {
    components: {
        ListHeader,
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList']),
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({
                email: ""
            });
            this.addFiltersByStartWithArray(['slug']);
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
        DdAbtestsStatuses
    ],
};
</script>
